<template>
  <div class="navbar">
    <div class="left">
      <h1>Eksistensi</h1>
      <nav>
        <router-link
          to="/main"
          class="nav-item"
          :class="{ active: isActive('/main')}">
          <i class="fa fa-clipboard icon-bordered"></i>
          Konsultasi
        </router-link>
        <router-link
          to="/psikolog-list"
          class="nav-item"
          :class="{ active: isActive('/psikolog-list')}">
          <i class="fa fa-users icon-bordered"></i>
          Psikolog
        </router-link>
        <router-link
          to="/history-booking"
          class="nav-item"
          :class="{ active: isActive('/history-booking')}">
          <i class="fa fa-tasks icon-bordered"></i>
          Riwayat Pemesanan
        </router-link>
      </nav>
    </div>

    <!-- Profile Section -->
    <div class="right" @click="toggleModal">
      <img class="profile-pic" src="https://via.placeholder.com/40" alt="Profile" />
      <div class="user-info">
        <span class="user-name">Moni Roy</span>
        <span class="user-role">Admin</span>
      </div>
      <i class="dropdown-icon"></i> 
      
      <!-- Small Modal for Profile/Logout -->
      <div v-if="isModalVisible" class="profile-modal">
        <router-link to="/profile" class="modal-item">Profile</router-link>
        <button class="modal-item" @click="logout">Logout</button>
      </div>
    </div>

    <!-- Click outside to close the modal -->
    <div v-if="isModalVisible" class="click-outside" @click="closeModal"></div>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      isModalVisible: false, // State to control modal visibility
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
    toggleModal() {
      this.isModalVisible = !this.isModalVisible; // Toggle the modal
    },
    closeModal() {
      this.isModalVisible = false; // Close the modal
    },
    logout() {
      // Handle logout logic here (e.g., clear tokens, redirect)
      this.$router.push("/");
    }
  }
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 15px 30px;
  border-bottom: 1px solid #e0e0e0;
}

.left {
  display: flex;
  align-items: center;
}

.left h1 {
  margin-right: 30px;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

nav {
  display: flex;
}

.nav-item {
  margin-right: 20px;
  text-decoration: none;
  color: black;
  padding: 10px 15px;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-item.active {
  background-color: #3b82f6;
  color: white;
}

.right {
  display: flex;
  align-items: center;
  position: relative;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: flex-start;
}

.user-name {
  font-weight: bold;
}

.user-role {
  font-size: 12px;
  color: gray;
}

.profile-pic {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.dropdown-icon-wrapper {
  display: inline-flex; 
  align-items: center; 
  justify-content: center; 
  width: 30px; 
  height: 30px; 
  border-radius: 50%; 
  background-color: white; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
  cursor: pointer; 
}

/* Dropdown Icon (arrow) */
.dropdown-icon {
  margin-left: 10px; 
  width: 0;
  height: 0;
  margin-top: 0;
  border-left: 5px solid transparent; 
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  cursor: pointer; 
}


/* Small Modal Styles */
.profile-modal {
  position: absolute;
  top: 60px; 
  right: 0;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 150px;
}

.modal-item {
  display: block;
  padding: 10px;
  text-align: left;
  width: 100%;
  border: none;
  background: none;
  font-size: 14px;
  color: black;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s;
}

.modal-item:hover {
  background-color: #f0f0f0;
}

/* Click outside area to close the modal */
.click-outside {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
</style>
