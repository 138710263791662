<!-- src/views/PsikologList.vue -->
<template>
    <div class="psychologist-list-page">
      <HeaderComponent />
      <h2 class="text-3xl font-bold mb-8">Konsultasi Online</h2>
      <div class="psychologist-grid">
        <PsychologistCard
          name="Jason Price, M. Psi"
          specialty="Tumbuh Kembang"
          img-src="https://via.placeholder.com/100"
          :isAction = true
        />
        <PsychologistCard
          name="Duane Dean, M. Psi"
          specialty="Pernikahan"
          img-src="https://via.placeholder.com/100"
          :isAction = true
        />
        <PsychologistCard
          name="Jonathan Barker, M. Psi"
          specialty="Takson Mapping"
          img-src="https://via.placeholder.com/100"
          :isAction = true
        />
        <!-- Add more psychologists as needed -->
      </div>
    </div>
  </template>
  
  <script>
  import HeaderComponent from '@/components/HeaderComponent.vue';
  import PsychologistCard from '@/components/PsychologistCard.vue';
  
  export default {
    components: {
      HeaderComponent,
      PsychologistCard,
    },
  };
  </script>
  
  <style scoped>
  .psychologist-list-page {
    background-color:white;
  }
  
  .psychologist-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 40px;
  }
  </style>
  