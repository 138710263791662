<template>
  <div class="auth-container">
    <div class="auth-box">
      <h2>Selamat Datang!</h2>
      <form @submit.prevent="login">
        <div class="input-group">
          <input type="email" v-model="email" placeholder="Email" required />
        </div>
        <div class="input-group">
          <input :type="showPassword ? 'text' : 'password'" v-model="password" placeholder="Password" required />
          <span @click="togglePassword" class="toggle-password">
            <!-- <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i> -->
          </span>
        </div>
        <a href="#" class="forgot-password">Lupa password?</a>
        <button type="submit" class="btn primary">Masuk</button>
        <p>Bukan member? <a href="/register">Daftar Sekarang</a></p>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
    };
  },
  methods: {
    async login() {
      try {
        const payload = {
          email: this.email,
          password: this.password,
        };

        const response = await axios.post('https://bidu.my.id/api/login', payload, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json' 
          }
        });

        if (response.data && response.data.token) {
          console.log("Login successful", response.data);
          localStorage.setItem('token', response.data.token);
          this.$router.push('/main');
        } else {
          console.error("Login failed", response.data);
        }
      } catch (error) {
        console.error("Login error", error);
        alert('Login failed. Please check your credentials.');
        // this.$router.push('/main');
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped>
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #4f88e0;
  background-size: cover;
  background-image: url('../assets/background-shapes.png');
}

.auth-box {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 360px;
  /* Batasi lebar auth-box */
}

.input-group {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

input {
  width: 100%;
  max-width: 300px;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.password-group input {
  padding-right: 40px;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.toggle-password i {
  font-size: 1.2rem;
  color: #007bff;
}

.forgot-password {
  text-align: left;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  color: #007bff;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.forgot-password:hover {
  text-decoration: underline;
}

.btn {
  background-color: #007bff;
  color: white;
  padding: 0.8rem;
  width: 100%;
  max-width: 330px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.btn.primary:hover {
  background-color: #0056b3;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
