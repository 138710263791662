<template>
  <div>
    <HeaderComponent />
    <h2 class="text-3xl font-bold">Psikolog</h2>
    <div class="space-y-6">
      <div class="bg-white rounded-lg  p-6 flex items-center">
        <div class="psychologist-card-wrapper">
          <PsychologistCard imgSrc="https://via.placeholder.com/150" name="Jason Price, M. Psi"
            specialty="Tumbuh Kembang" :isAction=false />
        </div>
        <div class="flex-1 bg-gray-50 rounded-lg p-6 ml-6"
          style="min-width: 900px; min-height: 250px; border: 0.5px solid grey;">
          <div class="text-left">
            <p class="text-black-500 font-semibold text-lg">Tentang Jason Price, M. Psi</p>
            <p class="text-black-500">Janji Konsultasi:</p>
            <li class="text-sm text-gray-500">S1 Sarjana Psikologi</li>
            <li class="text-sm text-gray-500">S2 Magister Psikologi Profesi Klinis</li>
            <p class="text-black-500">Nomor Izin Praktek</p>
            <p class="text-sm text-gray-500">SIPP : 0909-868966/7889-11-11-11</p>
          </div>
          <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white rounded-lg p-8">
        <p class="text-xl font-bold mb-4">Konseling dengan Psikolog Jason?</p>
        <div class="flex justify-end space-x-4">
          <button @click="confirmSession" class="bg-green-500 text-white px-4 py-2 rounded-lg">Lanjut ke Pembayaran</button>
          <button @click="showModal = false" class="bg-red-500 text-white px-4 py-2 rounded-lg">Batal</button>
        </div>
      </div>
    </div>
          <!-- Bagian Pilih Sesi -->
          <div v-if="!isPayment" class="text-left mt-4">
            <p class="text-black-500">Pilih Berdasarkan Sesi</p>
              <div class="input-group">
                <select v-model="gender" class="form-input" required>
                  <option value="" disabled>Jenis Kelamin</option>
                  <option value="Laki-Laki">Laki-Laki</option>
                  <option value="Perempuan">Perempuan</option>
                </select>
              </div>
              <div class="input-group">
                <input type="date" v-model="dob" class="form-input" placeholder="Tanggal Lahir" required />
              </div>
              <div class="input-group">
                <select v-model="status" class="form-input" required>
                  <option value="" disabled>Status Pernikahan</option>
                  <option value="Belum Menikah">Belum Menikah</option>
                  <option value="Menikah">Menikah</option>
                </select>
              </div>
              <button @click="confirmSession" class="bg-blue-500 text-white rounded-lg px-4 py-3 mt-4 w-full"
                style="border: none; width: 700px; padding: 15px;">
                Pilih Sesi
              </button>
          </div>

          <!-- Bagian Pembayaran -->
          <div v-if="isPayment" class="text-left mt-4">
            <p class="text-black-500">Pilih Pembayaran</p>
              <div class="input-group">
                <select v-model="gender" class="form-input" required>
                  <option value="" disabled>Jenis Kelamin</option>
                  <option value="Laki-Laki">Laki-Laki</option>
                  <option value="Perempuan">Perempuan</option>
                </select>
              </div>
              <div class="input-group">
                <input type="text" v-model="dob" class="form-input" placeholder="Tanggal Lahir" required />
              </div>
              <button class="bg-blue-500 text-white rounded-lg px-4 py-3 mt-4 w-full"
                style="border: none; width: 700px; padding: 15px;">
                Pembayaran
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import PsychologistCard from '@/components/PsychologistCard.vue';

export default {
  name: 'RiwayatPemesanan',
  components: {
    HeaderComponent,
    PsychologistCard,
  },
  data() {
    return {
      gender: '',
      dob: '',
      status: '',
      isPayment: false,  // Mengontrol apakah halaman pembayaran ditampilkan
      showModal: false,  // Mengontrol apakah modal konfirmasi ditampilkan
    };
  },
  methods: {
    confirmSession() {
      this.showModal = false;  // Tutup modal
      this.isPayment = true;   // Tampilkan halaman pembayaran
    }
  }
};
</script>


<style scoped>
.psychologist-card-wrapper {
  min-width: 200px;
}

.bg-gray-100 {
  background-color: white;
}

.min-h-screen {
  min-height: 100vh;
}

.p-8 {
  padding: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.font-bold {
  font-weight: 700;
}

.mb-8 {
  margin-bottom: 2rem;
}

.space-y-6>*+* {
  margin-top: 1.5rem;
}

.bg-white {
  background-color: white;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.p-6 {
  padding: 1.5rem;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.ml-6 {
  margin-left: 1.5rem;
}

.bg-blue-50 {
  background-color: #eff6ff;
}

.text-blue-700 {
  color: #1d4ed8;
}

.font-semibold {
  font-weight: 600;
}

.text-lg {
  font-size: 1.125rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-gray-500 {
  color: #6b7280;
}

.mt-4 {
  margin-top: 1rem;
}

.text-left {
  text-align: left;
  /* Menyediakan gaya untuk teks rata kiri */
}

.text-gray-400 {
  color: #9ca3af;
}

.bg-blue-500 {
  background-color: #3b82f6;
}

.text-white {
  color: white;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

button {
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2563eb;
}

input {
  width: 100%;
  max-width: 350px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select {
  width: 100%;
  max-width: 380px;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-group {
  margin-bottom: 16px;
  width: 100%;
}

.form-input {
  padding-top: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
}

button {
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2563eb;
}

.form-input {
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  max-width: 700px;
}

/* Menyembunyikan elemen 'Pilih Pembayaran' pada awalnya */
.pembayaran-section {
  display: none;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
}

.modal-button {
  background-color: #3b82f6;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-button:hover {
  background-color: #2563eb;
}

/* Menyembunyikan bagian 'Pilih Pembayaran' */
.pembayaran-section {
  display: none;
}
.pembayaran-section.active {
  display: block;
}

.sesi-section {
  display: block;
}
.sesi-section.hide {
  display: none;
}

</style>
