<template>
  <div class="card">
    <div class="image-container">
      <img :src="imgSrc" alt="Psychologist" class="profile-img" />
    </div>
    <div class="card-content">
      <h3>{{ name }}</h3>
      <p>{{ specialty }}</p>
      <div v-show="isAction" class="actions">
        <button @click="profile()" class="profile-btn">Lihat Profil</button>
        <button @click="profile()" class="schedule-btn">Jadwalkan Sekarang</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    specialty: String,
    imgSrc: String,
    isAction: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    profile() {
      this.$router.push('/psikolog-profile');
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    }
  }
};
</script>

<style scoped>
.card {
  background-color: white;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden; /* Menghilangkan overflow agar gambar pas dengan kartu */
  width: 350px; /* Lebar kartu dikurangi */
  margin: 0 auto; /* Membuat kartu berada di tengah */
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.image-container {
  width: 100%;
  height: 230px; /* Memperpanjang tinggi gambar */
  overflow: hidden; /* Menghilangkan bagian gambar yang tidak pas */
}

.profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Gambar memenuhi kontainer dan menjaga aspek rasio */
}

.card-content {
  padding: 15px 20px;
}

h3 {
  font-size: 18px;
  font-weight: bold;
  color: #111827;
  margin: 10px 0;
}

p {
  font-size: 14px;
  color: #6b7280;
}

.actions {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.profile-btn,
.schedule-btn {
  background-color: #3b82f6;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.profile-btn {
  background-color: white;
  color: #3b82f6;
  border: 2px solid #3b82f6;
}

.schedule-btn {
  background-color: #3b82f6;
}

.profile-btn:hover,
.schedule-btn:hover {
  background-color: #2563eb;
  color: white;
}

.profile-btn:hover {
  border-color: #2563eb;
}
</style>
