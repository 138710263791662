<template>
  <div class="consultation-page">
    <HeaderComponent />
    <div class="header-section">
      <h2>Konsultasi</h2>
      <input type="text" placeholder="Cari..." class="search-input" />
    </div>

    <div class="consultation-options">
      <div class="option active">
        <div style="display: flex; flex-direction: column; align-items: center;">
          <i class="fa fa-video" style="color: #1E90FF; font-size: 24px;"></i>
          <span>Voice/Video Call</span>
        </div>
      </div>
      <div class="option">
        <div style="display: flex; flex-direction: column; align-items: center;">
        <i class="fas fa-check-circle" style="color: black; font-size: 24px;"></i>
        Janji Tatap Muka
      </div>
      </div>
    </div>

    <div class="psychologist-list">
      <PsychologistCard 
        v-for="(psychologist, index) in psychologists" 
        :key="index"
        :name="psychologist.name"
        :specialty="psychologist.specialty"
        :img-src="psychologist.imgSrc"
        :isAction="true"
      />
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import PsychologistCard from '@/components/PsychologistCard.vue';

export default {
  components: {
    HeaderComponent,
    PsychologistCard,
  },
  data() {
    return {
      psychologists: [
        { 
          name: "Rosie Glover, M. Psi", 
          specialty: "Pernikahan", 
          imgSrc: "https://www.ciputramedicalcenter.com/wp-content/uploads/2020/12/shutterstock_791244661sozeee.jpg"
        },
        { 
          name: "Patrick Greer, M. Psi", 
          specialty: "Putus Cinta", 
          imgSrc: "https://png.pngtree.com/thumb_back/fw800/background/20221002/pngtree-doctor-psychologist-man-friendly-photo-image_28377702.jpg"
        },
        { 
          name: "Darrell Ortega, M. Psi", 
          specialty: "Tumbuh Kembang", 
          imgSrc: "https://asset-a.grid.id/crop/48x7:639x422/360x240/photo/2023/05/06/untitledjpg-20230506112747.jpg"
        }
      ]
    };
  }
};
</script>

<style scoped>
.consultation-page {
  background-color: #ffffff;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 60px;
  margin-right: 60px;
}

.header-section h2 {
  font-size: 24px;
  font-weight: bold;
  color: #111827;
}

.search-input {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  font-size: 16px;
  width: 200px;
}

.consultation-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 20px;
  padding-left: 65px;
  padding-right: 65px;
}

.option {
  flex: 1;
  text-align: center;
  padding: 15px;
  border-radius: 12px;
  background-color: white;
  color: #374151;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  /* Shadow di semua sisi */
  padding: 3em;
}


.option i {
  font-size: 24px;
  margin-bottom: 10px;
}

.option.active {
  background-color: white;
  color: #3b82f6;
  border: 2px solid #3b82f6;
}


.option.active i {
  color: white;
}

.psychologist-list {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
</style>