<template>
    <HeaderComponent />
    <h2 class="text-3xl font-bold">Riwayat Pemesanan</h2>
    <div class="space-y-6">
      <div class="bg-white rounded-lg shadow-md p-6 flex items-center">
        <div class="psychologist-card-wrapper">
          <PsychologistCard
            imgSrc="https://via.placeholder.com/150"
            name="Jason Price, M. Psi"
            specialty="Tumbuh Kembang"
            :isAction = false
          />
        </div>

        <div class="flex-1 bg-blue-50 rounded-lg p-6 ml-6" style="min-width: 900px; min-height: 250px;"> <!-- Menambahkan padding di sini -->
          <div class="text-left">
            <p class="text-blue-700 font-semibold text-lg">Konsultasi Online</p>
            <p class="text-sm text-gray-500">Janji Konsultasi:</p>
            <p class="text-sm text-gray-500">28 Agustus 2024 | 17:00</p>
          </div>
          <div class="text-left mt-4">
            <p class="text-sm text-gray-500">Total: 300.000</p>
            <p class="text-sm text-gray-400">25 Agustus 2024 | 18:14</p>
            <button class="bg-blue-500 text-white rounded-lg px-4 py-2 mt-2">
              Jadwalkan Ulang
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import PsychologistCard from '@/components/PsychologistCard.vue';

export default {
  name: 'RiwayatPemesanan',
  components: {
    HeaderComponent,
    PsychologistCard,
  },
};
</script>

<style scoped>
.psychologist-card-wrapper {
  min-width: 200px; 
}

.bg-gray-100 {
  background-color: white;
}

.min-h-screen {
  min-height: 100vh;
}

.p-8 {
  padding: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.font-bold {
  font-weight: 700;
}

.mb-8 {
  margin-bottom: 2rem;
}

.space-y-6 > * + * {
  margin-top: 1.5rem;
}

.bg-white {
  background-color: white;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.p-6 {
  padding: 1.5rem;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.ml-6 {
  margin-left: 1.5rem;
}

.bg-blue-50 {
  background-color: #eff6ff;
}

.text-blue-700 {
  color: #1d4ed8;
}

.font-semibold {
  font-weight: 600;
}

.text-lg {
  font-size: 1.125rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-gray-500 {
  color: #6b7280;
}

.mt-4 {
  margin-top: 1rem;
}

.text-left {
  text-align: left; /* Menyediakan gaya untuk teks rata kiri */
}

.text-gray-400 {
  color: #9ca3af;
}

.bg-blue-500 {
  background-color: #3b82f6;
}

.text-white {
  color: white;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

button {
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2563eb;
}
</style>
