<template>
  <div class="auth-container">
    <div class="auth-box">
      <h1>Daftar</h1>
      <form @submit.prevent="register">
        <div class="input-group">
          <input type="text" v-model="firstname" placeholder="Nama Depan" required />
        </div>
        <div class="input-group">
          <input type="text" v-model="lastname" placeholder="Nama Belakang" required />
        </div>
        <div class="input-group">
          <input type="email" v-model="email" placeholder="Email" required />
        </div>
        <div class="input-group">
          <input type="password" v-model="password" placeholder="Password" required />
        </div>
        <div class="input-group">
          <input type="text" v-model="phone" placeholder="Nomor Whatsapp" required />
        </div>
        <div class="input-group">
          <select v-model="gender" required>
            <option value="" disabled selected>Jenis Kelamin</option>
            <option value="female">Perempuan</option>
            <option value="male">Laki-Laki</option>
          </select>
        </div>
        <div class="input-group">
          <input type="date" v-model="dob" placeholder="Tanggal Lahir" required />
        </div>
        <div class="input-group">
          <select v-model="marriage" required>
            <option value="" disabled selected>Status Pernikahan</option>
            <option value="false">Belum Menikah</option>
            <option value="true">Menikah</option>
          </select>
        </div>
        <div class="checkbox-group">
          <input type="checkbox" v-model="agreement" required /> Saya sudah membaca dan menyetujui Syarat dan Ketentuan
        </div>
        <button type="submit" class="btn primary">Daftar</button>
        <p>Sudah punya akun? <a href="/">Login Sekarang</a></p>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      phone: '',
      gender: '',
      dob: '',
      marriage: '',
      agreement: false
    };
  },
  methods: {
    async register() {
      if (!this.agreement) {
        alert("Anda harus menyetujui syarat dan ketentuan.");
        return;
      }

      const payload = {
        dob: this.dob,
        email: this.email,
        firstname: this.firstname,
        gender: this.gender,
        lastname: this.lastname,
        marriage: this.marriage === 'true', // Mengubah nilai menjadi boolean
        password: this.password,
        phone: this.phone
      };

      try {
        const response = await axios.post('https://bidu.my.id/api/createuser', payload, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        console.log("User created:", response.data);
        // Alihkan ke halaman login atau berikan notifikasi sukses
        this.$router.push('/');
      } catch (error) {
        console.error("Error creating user:", error);
        alert("Terjadi kesalahan saat mendaftar.");
      }
    }
  }
};
</script>

<style scoped>
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../assets/background-shapes.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.auth-box {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 450px;
}

.input-group {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.input-group {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

input {
  width: 100%;
  max-width: 350px; 
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input-checkbox {
  width: 100%;
  max-width: 350px; 
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: left;
}

select {
  width: 100%;
  max-width: 380px; 
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.checkbox-group {
  display: flex;
  align-items: start;
  max-width: 300px;
  margin: 5 auto;
  text-align: left;
  margin-bottom: 30px;
  margin-left: 30px;
}

input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
}

.btn {
  background-color: #007bff;
  color: white;
  padding: 0.8rem;
  width: 100%;
  max-width: 380px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.btn.primary:hover {
  background-color: #0056b3;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
