import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/LoginView.vue';
import Register from '../views/RegisterView.vue';
import Main from '../views/MainView.vue';
import PsikologList from '../views/PsikologList.vue';
import HistoryBooking from '@/views/HistoryBooking.vue';
import PsikologProfile from '@/views/PsikologProfile.vue';


const routes = [
  { path: '/', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: Register },
  { path: '/main', name: 'Main', component: Main },
  { path: '/psikolog-list', name: 'PsikologList', component: PsikologList },
  { path: '/history-booking', name: 'HistoryBooking', component: HistoryBooking },
  { path: '/psikolog-profile', name: 'PsikologProfile', component: PsikologProfile },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
